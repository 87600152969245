<template>
  <div class="Main_xy" id="xiaoying">
    <!-- logo+切换+二维码 -->
    <div class="header_xy">
      <div class="logo">
        <img src="@/assets/second/sc_logo.png" alt="" />
      </div>
      <div class="tab_club">
        <div class="youzhan" @click="wx_youzhan" ref="onePage">
          <span :class="display ? 'active_wx' : 'default_wx'">油站小程序</span>
        </div>
        <div class="tab_bar"></div>
        <div class="qiye" @click="wx_qiye" ref="twoPage">
          <span :class="!display ? 'active_wx' : 'default_wx'">企业小程序</span>
        </div>
      </div>
      <div class="qrcode">
        <img src="@/assets/second/vx_youzhan.jpg" alt="" v-if="display" />
        <img src="@/assets/second/vx_qiye.jpg" alt="" v-else />
      </div>
    </div>
    <!-- 版心 -->
    <div class="content">
      <!-- 导航 -->
      <div class="nav">
        <ul>
          <li>
            <a
              href="#home"
              @mouseenter="enter(1)"
              @mouseleave="leave()"
              :class="isShow === 1 ? 'active' : ''"
              >首页</a
            >
          </li>
          <li>
            <a href="#xiaoying" class="active" style="color: #fff">小鹰加油</a>
          </li>
          <li>
            <a
              href="#zonghe"
              @mouseenter="enter(3)"
              @mouseleave="leave()"
              :class="isShow === 3 ? 'active' : ''"
              >综合能源</a
            >
          </li>
          <li>
            <a
              href="#chanpin"
              @mouseenter="enter(4)"
              @mouseleave="leave()"
              :class="isShow === 4 ? 'active' : ''"
              >产品矩阵</a
            >
          </li>
          <li>
            <a
              href="#IP"
              @mouseenter="enter(5)"
              @mouseleave="leave()"
              :class="isShow === 5 ? 'active' : ''"
              >IP体系</a
            >
          </li>
          <li>
            <a
              href="#zhaoxian"
              @mouseenter="enter(6)"
              @mouseleave="leave()"
              :class="isShow === 6 ? 'active' : ''"
              >招贤纳士</a
            >
          </li>
          <li>
            <a
              href="#aboutUs"
              @mouseenter="enter(7)"
              @mouseleave="leave()"
              :class="isShow === 7 ? 'active' : ''"
              >关于我们</a
            >
          </li>
        </ul>
      </div>
      <!-- 地图 -->
      <div class="map reveal-top">
        <img src="@/assets/second/map.png" alt="" />
      </div>
      <!-- 介绍 -->
      <div class="detail">
        <div class="detail_item reveal-right1">
          <div class="detail_img">
            <img src="@/assets/second/icon_1.png" alt="" />
          </div>
          <div class="detail_text">
            <span>油站端业务已经覆盖全国30+省市地区， 3000+站点。</span>
          </div>
        </div>
        <div class="detail_item reveal-right2">
          <div class="detail_img">
            <img src="@/assets/second/icon_2.png" alt="" />
          </div>
          <div class="detail_text">
            <span>聚合平台及线下流量，为油站流量精准 运营，增益盈收。</span>
          </div>
        </div>
        <div class="detail_item reveal-right3">
          <div class="detail_img">
            <img src="@/assets/second/icon_3.png" alt="" />
          </div>
          <div class="detail_text">
            <span>
              为油站业主提供多流合一的金税管理服
              务，互联网支付优势使油站收单更省心。</span
            >
          </div>
        </div>
        <div class="detail_item reveal-right4">
          <div class="detail_img">
            <img src="@/assets/second/icon_4.png" alt="" />
          </div>
          <div class="detail_text">
            <span>搭载环球通数据中台系统，为油站提供 安全的云数据管理。</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import scrollReveal from "scrollreveal";
export default {
  data() {
    return {
      isShow: 0,
      display: true,
      scrollReveal: scrollReveal(),
    };
  },
  //项目中执行，自定义类名。
  mounted() {
    this.$refs.onePage.style.border = "1px solid #3251A0";
    this.scrollReveal.reveal(".reveal-top", {
      // 动画的时长
      duration: 1000,
      // 延迟时间
      delay: 500,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: "top",
      // 回滚的时候是否再次触发动画
      reset: true,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: "200px",
      // 其他可用的动画效果
      opacity: 0.001,
      easing: "linear",
      scale: 0.9,
    });
    this.scrollReveal.reveal(".reveal-right1", {
      // 动画的时长
      duration: 1000,
      // 延迟时间
      delay: 1000,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: "right",
      // 回滚的时候是否再次触发动画
      reset: true,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: "200px",
      // 其他可用的动画效果
      opacity: 0.001,
      easing: "linear",
      scale: 0.9,
    });
    this.scrollReveal.reveal(".reveal-right2", {
      // 动画的时长
      duration: 1000,
      // 延迟时间
      delay: 1500,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: "right",
      // 回滚的时候是否再次触发动画
      reset: true,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: "200px",
      // 其他可用的动画效果
      opacity: 0.001,
      easing: "linear",
      scale: 0.9,
    });
    this.scrollReveal.reveal(".reveal-right3", {
      // 动画的时长
      duration: 1000,
      // 延迟时间
      delay: 2000,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: "right",
      // 回滚的时候是否再次触发动画
      reset: true,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: "200px",
      // 其他可用的动画效果
      opacity: 0.001,
      easing: "linear",
      scale: 0.9,
    });
    this.scrollReveal.reveal(".reveal-right4", {
      // 动画的时长
      duration: 1000,
      // 延迟时间
      delay: 2500,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: "right",
      // 回滚的时候是否再次触发动画
      reset: true,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: "200px",
      // 其他可用的动画效果
      opacity: 0.001,
      easing: "linear",
      scale: 0.9,
    });
  },
  methods: {
    enter(index) {
      // this.$refs.onePage.style.color = "#fff"
      this.isShow = index;
    },
    leave() {
      // this.$refs.onePage.style.color = "#c1d7ce"
      this.isShow = 0;
    },
    wx_youzhan() {
      this.display = true;
      // #3251A0
      this.$refs.onePage.style.border = "1px solid #3251A0";
      this.$refs.twoPage.style.border = "none";
    },
    wx_qiye() {
      this.display = false;
      this.$refs.twoPage.style.border = "1px solid #3251A0";
      this.$refs.onePage.style.border = "none";
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.Main_xy {
  width: 100vw;
  height: 100vh;
  background-image: url(@/assets/second/bc_xiaoying.png);
  background-position: center;
  z-index: 999;
  background-size: cover;
  background-position: center;
}
/* 头部 */
.header_xy {
  /* max-width: 1520px; */
  margin: 0 auto;
  display: flex;
  /* grid-template-columns: 2.5fr repeat(2, 1fr); */
  justify-content: center;
  text-align: center;
  padding-top: 68px;
  align-items: center;
  position: relative;
}
.logo {
  flex: 0.6;
  text-align: left;
}
.qrcode {
  width: 108px;
  height: 108px;
  flex: 0.2;
  text-align: right;
}
.qrcode img {
  width: 108px;
}
.tab_club {
  display: flex;
}
.youzhan {
  width: 160px;
  height: 66px;
  background-color: #1e2645;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}
.default_wx {
  color: #7a7f8b;
  font-size: 24px;
  line-height: 66px;
}
.active_wx {
  color: #fff;
  font-size: 24px;
  line-height: 66px;
}
.qiye {
  width: 160px;
  height: 66px;
  background-color: #1e2645;
  border-radius: 4px;
  cursor: pointer;
}
/* 版心 */
/* 导航 */
.content {
  display: flex;
  max-width: 1720px;
  margin: 38px auto 0;
}
.nav {
  width: 268px;
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #616162;

  text-align: center;
}
.nav ul li {
  margin-top: 37px;
  list-style: none;
  display: block;
  height: 37px;
}
.nav ul li a {
  color: #a7baeb;
  font-size: 22px;
  width: 90px;
}
.active {
  box-sizing: border-box;
  display: inline-block;
  color: #fff !important;
  border-bottom: 4px solid #00b4ff;
  transition: 0.5s;
}
/* 地图 */
.map {
  width: 733px;
  height: 604px;
}
.map img {
  width: 733px;
  height: 604px;
}
/* 详情 */
.detail {
  width: 467px;
  height: 323px;
  padding-top: 240px;
}
.detail_item {
  width: 467px;
  color: #fff;
  vertical-align: middle;
  display: flex;
  padding-bottom: 20px;
}
.detail_img {
  width: 40px;
  height: 50px;
  vertical-align: middle;
  margin-right: 17px;
}
.detail_text span {
  font-size: 24px;
  vertical-align: middle;
}
@media (min-width: 1900px) {
  .map {
    width: 733px;
    height: 604px;
  }
  
}
@media (max-width: 1600px) {
  .map {
    width: 633px;
    height: 504px;
  }
  .content {
    display: flex;
    justify-content: space-around;
    max-width: 1720px;
    margin: 38px auto 0;
  }
  .map img {
    width: 633px;
    height: 504px;
  }
  .detail_text span {
    font-size: 20px;
    vertical-align: middle;
  }
  .detail_img{
    width: 35px;
  }
  .logo img {
    width: 180px;
}
.youzhan,.qiye {
  width: 138px;
  height: 44px;
}
.active_wx,.default_wx{
    font-size: 17px;
    line-height: 44px;
}
.qrcode img {
    width: 85px;
}
}
@media (max-width: 1500px) {
  .map {
    width: 633px;
    height: 504px;
  }
  .content {
    display: flex;
    justify-content: space-around;
    max-width: 1720px;
    margin: 38px auto 0;
  }
  .map img {
    width: 633px;
    height: 504px;
  }
  .detail_text span {
    font-size: 20px;
    vertical-align: middle;
  }
  .detail_img{
    width: 35px;
  }
  .logo img {
    width: 180px;
}
.youzhan,.qiye {
  width: 138px;
  height: 44px;
}
.active_wx,.default_wx{
    font-size: 17px;
    line-height: 44px;
}
.qrcode img {
    width: 85px;
}
}
@media (max-width: 1400px) {
  .map {
    width: 633px;
    height: 504px;
  }
  .content {
    display: flex;
    justify-content: space-around;
    max-width: 1720px;
    margin: 38px auto 0;
  }
  .map img {
    width: 633px;
    height: 504px;
  }
  .detail_text span {
    font-size: 20px;
    vertical-align: middle;
  }
  .detail_img{
    width: 35px;
  }
  .logo img {
    width: 180px;
}
.youzhan,.qiye {
  width: 138px;
  height: 44px;
}
.active_wx,.default_wx{
    font-size: 17px;
    line-height: 44px;
}
.qrcode img {
    width: 85px;
}
}
@media (max-width: 1300px) {
  .nav {
    display: none;
  }
  .map {
    width: 633px;
    height: 504px;
  }
  .content {
    display: flex;
    justify-content: space-around;
    max-width: 1720px;
    margin: 38px auto 0;
  }
  .map img {
    width: 633px;
    height: 504px;
  }
  .detail_text span {
    font-size: 20px;
    vertical-align: middle;
  }
  .detail_img{
    width: 35px;
  }
  .logo img {
    width: 180px;
}
.youzhan,.qiye {
  width: 138px;
  height: 44px;
}
.active_wx,.default_wx{
    font-size: 17px;
    line-height: 44px;
}
.qrcode img {
    width: 85px;
}
}
@media (max-width: 1099px) {
  .nav {
    display: none;
  }
  .map {
    width: 633px;
    height: 504px;
  }
  .content {
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;
    max-width: 1720px;
    margin: 38px auto 0;
    height: 500px;
  }
  .map img {
    width: 633px;
    height: 504px;
  }
  .detail_text span {
    font-size: 20px;
    vertical-align: middle;
  }
  .detail_img{
    width: 35px;
  }
  .logo img {
    width: 180px;
}
.detail{
    width: 467px;
    height: 323px;
    padding-top: 0px;
}
}
@media (max-width: 800px) {
  .nav {
    display: none;
  }
  .map {
    width: 533px;
    height: 404px;
  }
  .content {
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;
    max-width: 1720px;
    margin: 38px auto 0;
    height: 700px;
  }
  .map img {
    width: 533px;
    height: 404px;
  }
  .detail_text span {
    font-size: 18px;
    vertical-align: middle;
  }
  .detail_img{
    width: 30px;
  }
  .logo img {
    width: 160px;
}
.detail{
    width: 367px;
    height: 223px;
    padding-top: 0px;
}
.youzhan,.qiye {
  width: 100px;
  height: 30px;
}
.active_wx,.default_wx{
    font-size: 14px;
    line-height: 30px;
}
.qrcode img {
    width: 70px;
}
}
@media (max-width: 600px) {
  .nav {
    display: none;
  }
  .map {
    width: 433px;
    height: 354px;
  }
  .content {
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;
    max-width: 1720px;
    margin: 38px auto 0;
    height: 700px;
  }
  .map img {
    width: 433px;
    height: 354px;
  }
  .detail_text span {
    font-size: 14px;
    vertical-align: middle;
  }
  .detail_img{
    width: 25px;
  }
  .logo img {
    width: 140px;
}
.detail{
    width: 432px;
    height: 123px;
    padding-top: 0px;
}
.youzhan,.qiye {
  width: 100px;
  height: 30px;
}
.active_wx,.default_wx{
    font-size: 14px;
    line-height: 30px;
}
.qrcode img {
    width: 70px;
}
}
@media (max-width: 550px) {
  .nav {
    display: none;
  }
  .map {
    width: 353px;
    height: 304px;
  }
  .content {
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;
    max-width: 1720px;
    margin: 38px auto 0;
    height: 700px;
  }
  .map img {
    width: 353px;
    height: 304px;
  }
  .detail_text span {
    font-size: 14px;
    vertical-align: middle;
  }
  .detail_img{
    width: 25px;
  }
  .logo img {
    width: 140px;
}
.detail{
    width: 310px;
    height: 123px;
    padding-top: 0px;
}
.detail_item {
    width: 330px;
    padding-bottom: 10px;
}
.youzhan,.qiye {
  width: 100px;
  height: 30px;
}
.active_wx,.default_wx{
    font-size: 14px;
    line-height: 30px;
}
.qrcode img {
    width: 70px;
}
}
@media (max-width: 400px) {
  .nav {
    display: none;
  }
  .map {
    width: 253px;
    height: 204px;
  }
  .content {
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;
    max-width: 1720px;
    margin: 38px auto 0;
    height: 700px;
  }
  .map img {
    width: 253px;
    height: 204px;
  }
  .detail_text span {
    font-size: 14px;
    vertical-align: middle;
  }
  .detail_img{
    width: 25px;
  }
  .logo img {
    width: 80px;
    height: 100%;
}
.detail{
    width: 310px;
    height: 283px;
    padding-top: 0px;
}
.detail_item {
    width: 330px;
    padding-bottom: 10px;
}
.youzhan,.qiye {
  width: 100px;
  height: 30px;
}
.active_wx,.default_wx{
    font-size: 14px;
    line-height: 30px;
}
.qrcode img {
    width: 70px;
}
}
@media (max-width: 375px) {
  .nav {
    display: none;
  }
  .map {
    width: 253px;
    height: 204px;
  }
  .content {
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;
    max-width: 1720px;
    margin: 38px auto 0;
    height: 700px;
  }
  .map img {
    width: 253px;
    height: 204px;
  }
  .detail_text span {
    font-size: 12px;
    vertical-align: middle;
  }
  .detail_img{
    width: 20px;
  }
  .logo img {
    width: 80px;
    height: 100%;
}
.detail{
    width: 336px;
    height: 283px;
    padding-top: 0px;
}
.detail_item {
    width: 330px;
    padding-bottom: 10px;
}
.youzhan,.qiye {
  width: 80px;
  height: 25px;
}
.active_wx,.default_wx{
    font-size: 14px;
    line-height: 25px;
}
.qrcode img {
    width: 70px;
}
}
</style>