<template>
  <div class="warp" id="chanpin">
    <div class="area">
      <div class="parcel">
        <!-- 导航 -->
        <div class="nav">
          <ul>
            <li>
              <a
                href="#"
                @mouseenter="enter(1)"
                @mouseleave="leave()"
                :class="isShow === 1 ? 'active' : ''"
                ref="onePage"
                >首页</a
              >
            </li>
            <li>
              <a
                href="#zonghe"
                @mouseenter="enter(2)"
                @mouseleave="leave()"
                :class="isShow === 2 ? 'active' : ''"
                >综合能源</a
              >
            </li>
            <li>
              <a
                href="#xiaoying"
                @mouseenter="enter(3)"
                @mouseleave="leave()"
                :class="isShow === 3 ? 'active' : ''"
                >小鹰加油</a
              >
            </li>

            <li>
              <a href="#chanpin" class="active" style="color: #686a88"
                >产品矩阵</a
              >
            </li>
            <li>
              <a
                href="#IP"
                @mouseenter="enter(5)"
                @mouseleave="leave()"
                :class="isShow === 5 ? 'active' : ''"
                >IP体系</a
              >
            </li>
            <li>
              <a
                href="#zhaoxian"
                @mouseenter="enter(6)"
                @mouseleave="leave()"
                :class="isShow === 6 ? 'active' : ''"
                >招贤纳士</a
              >
            </li>
            <li>
              <a
                href="#aboutUs"
                @mouseenter="enter(7)"
                @mouseleave="leave()"
                :class="isShow === 7 ? 'active' : ''"
                >关于我们</a
              >
            </li>
          </ul>
        </div>
        <div style="display: flex">
          <div>
            <div class="title reveal-right1">我们的产品</div>
            <div class="prod reveal-right2">
              <div>
                <ul>
                  <li
                    v-for="(item, index) in prodList"
                    :key="index"
                    @click="click(index)"
                    :class="{ actives: index == num }"
                  >
                    <img :src="item.img" />
                    <div>{{ item.text }}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div v-for="(item, key) in imgList" :key="key">
            <div class="oil" v-if="key == num">
              <div class="titles reveal-right3">
                {{ item.title }}
                <div class="vert">{{ item.lv }}</div>
              </div>
              <div class="subhead reveal-right4">
                {{ item.subhead }}
              </div>
              <!-- 轮播 -->
              <div class="book reveal-right5">
                <swiper :options="swiperOption" ref="mySwiper">
                  <swiper-slide>
                    <div class="swiperImg">
                      <img :src="item.img" class="imgitem" />
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="swiperImg">
                      <img :src="item.imgs" class="imgitem" />
                    </div>
                  </swiper-slide>
                  <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import scrollReveal from "scrollreveal";
// import imgZoom from "vue2.0-zoom";

export default {
  name: "Home",
  components: {
    swiper,
    swiperSlide,
    // imgZoom,
  },
  data() {
    return {
      isShow: 0,
      scrollReveal: scrollReveal(),
      swiperOption: {
        // direction: "vertical",
        // loop: true,
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      num: 0,
      prodList: [
        {
          text: "小鹰加油",
          img: require("../../assets/product/eaglet.png"),
        },
        {
          text: "环球通版喂..",
          img: require("../../assets/product/round.png"),
        },
        {
          text: "途油",
          img: require("../../assets/product/oli.png"),
        },

        {
          text: "HUfU开源",
          img: require("../../assets/product/fu.png"),
        },

        {
          text: "行客",
          img: require("../../assets/product/line.png"),
        },

        {
          text: "研油院",
          img: require("../../assets/product/research.png"),
        },

        {
          text: "即将上线",
          img: require("../../assets/product/look.png"),
        },
        {
          text: "即将上线",
          img: require("../../assets/product/look.png"),
        },
      ],
      imgList: [
        {
          title: "小鹰加油",
          subhead:
            "小鹰加油，致力于构建具有国际竞争力的数智能源生态，助力加油站运营公/私域流量，扩大销量；油品供应链赋能，减低成本，提高油站品牌力；为企业及个人提供数智化能源管理及金融解决方案；解决企业开票难、管理难，提升经营效率",
          img: require("../../assets/product/xiaoy.png"),
          imgs: require("../../assets/product/xiaoying.png"),
        },
        {
          title: "环球通版喂车",
          subhead:
            "油站零管系统、CRM系统、营销体系、智能支付体系（无感支付、移动支付、刷脸付等）全面互联互通，为油站前庭场景提供一体化解决方案，提升了油站的运营效率及车主体验。",
          img: require("../../assets/product/act.png"),
          imgs: require("../../assets/product/actt.png"),
        },
        {
          title: "途油",
          subhead:
            "途油，是基于加油站场景，为管理者、经营者、使用者提供整体、快捷、便利、高效的整体解决方案。能够安全、稳定、灵活、长期地服务于加油站整体运营管理。",
          img: require("../../assets/product/pco.png"),
          imgs: require("../../assets/product/pct.png"),
          lv: "V3.24",
        },
        {
          title: "虎符",
        },
        {
          title: "行客",
        },
        {
          title: "研油院",
          img: require("../../assets/product/oil.png"),
          imgs: require("../../assets/product/oils.png"),
          subhead:
            "研油院根据油站的营运分析结果，智能推荐最匹配的运营或管理方案。也可作为运营策略手段做油站分析，以油站经营多维度分析结果，进行线上、线下运营介入方向，从模型到运营落地，呈现油站阶段性的运营成果",
        },
        {},
        {},
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.scrollReveal.reveal(".reveal-right1", {
        // 动画的时长
        duration: 1000,
        // 延迟时间
        delay: 200,
        // 动画开始的位置，'bottom', 'left', 'top', 'right'
        origin: "right",
        // 回滚的时候是否再次触发动画
        reset: true,
        // 在移动端是否使用动画
        mobile: false,
        // 滚动的距离，单位可以用%，rem等
        distance: "200px",
        // 其他可用的动画效果
        opacity: 0.001,
        easing: "linear",
        scale: 0.9,
      });
      this.scrollReveal.reveal(".reveal-right2", {
        // 动画的时长
        duration: 1000,
        // 延迟时间
        delay: 300,
        // 动画开始的位置，'bottom', 'left', 'top', 'right'
        origin: "right",
        // 回滚的时候是否再次触发动画
        reset: true,
        // 在移动端是否使用动画
        mobile: false,
        // 滚动的距离，单位可以用%，rem等
        distance: "200px",
        // 其他可用的动画效果
        opacity: 0.001,
        easing: "linear",
        scale: 0.9,
      });
      this.scrollReveal.reveal(".reveal-right3", {
        // 动画的时长
        duration: 1000,
        // 延迟时间
        delay: 400,
        // 动画开始的位置，'bottom', 'left', 'top', 'right'
        origin: "right",
        // 回滚的时候是否再次触发动画
        reset: true,
        // 在移动端是否使用动画
        mobile: false,
        // 滚动的距离，单位可以用%，rem等
        distance: "200px",
        // 其他可用的动画效果
        opacity: 0.001,
        easing: "linear",
        scale: 0.9,
      });
      this.scrollReveal.reveal(".reveal-right4", {
        // 动画的时长
        duration: 1000,
        // 延迟时间
        delay: 500,
        // 动画开始的位置，'bottom', 'left', 'top', 'right'
        origin: "top",
        // 回滚的时候是否再次触发动画
        reset: true,
        // 在移动端是否使用动画
        mobile: false,
        // 滚动的距离，单位可以用%，rem等
        distance: "200px",
        // 其他可用的动画效果
        opacity: 0.001,
        easing: "linear",
        scale: 0.9,
      });
      this.scrollReveal.reveal(".reveal-right5", {
        // 动画的时长
        duration: 1000,
        // 延迟时间
        delay: 600,
        // 动画开始的位置，'bottom', 'left', 'top', 'right'
        origin: "top",
        // 回滚的时候是否再次触发动画
        reset: true,
        // 在移动端是否使用动画
        mobile: false,
        // 滚动的距离，单位可以用%，rem等
        distance: "200px",
        // 其他可用的动画效果
        opacity: 0.001,
        easing: "linear",
        scale: 0.9,
      });
    });
  },
  methods: {
    enter(index) {
      // this.$refs.onePage.style.color = "#fff"
      this.isShow = index;
    },
    leave() {
      // this.$refs.onePage.style.color = "#c1d7ce"
      this.isShow = 0;
    },
    clickLeft() {
      this.change();
    },
    click(index) {
      this.num = index;
    },
  },
};
</script>

<style scoped lang='scss'>
.actives {
  background: #a9aac2;
  border-radius: 23px;
  width: 132px;
  height: 161px;
}
::v-deep .swiper-pagination-bullet {
  width: 150px;
  margin: 0;
  height: 13px;
  border-radius: 5px;
  background-color: #8990a5;
  transform: translateX(0px);
  opacity: 0;
  transition: 1s;
}
::v-deep .swiper-pagination-bullet-active {
  width: 150px;
  height: 13px;
  border-radius: 5px;
  background-color: #d8d9e9;
  // transform: translateX(150px);
  opacity: 1;
  transition: 1s;
}
::v-deep .swiper-container-horizontal > .swiper-pagination-bullets {
  padding: 2px 0;
  bottom: 70px;
  left: 50%;
  width: 340px;
  height: 16px;
  transform: translateX(-50%);
  background-color: #8990a5;
  border-radius: 8px;
  // transition: 1s;
}

.book .swiper-container {
  position: relative;
  width: 800px;
  height: 600px;
}
.book .swiper-container .swiper-slide {
  width: 100%;
  line-height: 200px;
  color: #000;
  font-size: 16px;
  text-align: center;
}
.warp {
  width: 100%;
  height: 100vh;
  background: #f2f2f2;
}
.area {
  width: 1596px;
  margin: 0 auto;
}
.title {
  font-size: 84px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #5b5b5b;
  padding-top: 85px;
  padding-bottom: 71px;
}
.titles {
  font-size: 61px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #5b5b5b;
  display: flex;
  align-items: flex-end;
}
.vert {
  font-size: 38px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #5b5b5b;
}
.swiper-slide {
  height: 410px !important;
}
.prod {
  text-decoration: none;
  width: 556px;
  height: 571px;
  background: #d8d9e9;
  border-radius: 12px;
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    li {
      list-style: none;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #5b5b5b;
      width: 25%;
      text-align: center;
      margin-top: 25px;
      height: 161px;
      padding-top: 10px;
      img {
        width: 108px;
        height: 108px;
      }
    }
  }
}
.subhead {
  width: 767px;
  height: 95px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #5b5b5b;

  margin-top: 29px;
}
.oil {
  margin-left: 28px;
  margin-top: 109px;
}
.parcel {
  display: flex;
}
.book {
  margin-top: 42px;
  width: 1024px;
  height: 482px;
  background: url("../../assets/product/notebook.png") no-repeat center;
  background-size: 100% 100%;
  margin-left: -140px;
  text-align: center;
  position: sticky;
  // line-height: 10px;
}
.nav {
  width: 105px;
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #b3b5cb;
  margin-top: 265px;
  margin-right: 15px;
}
.nav ul li {
  text-align: center;
  margin-top: 37px;
  list-style: none;
  height: 37px;
}
.nav ul li a {
  color: #b3b5cb;
  font-size: 22px;
  width: 90px;
}

.active {
  box-sizing: border-box;
  display: inline-block;
  color: #686a88 !important;
  border-bottom: 4px solid #686a88;
  transition: 0.5s;
}
.imgitem {
  margin-top: 22px;
  width: 731px;
  height: 410px;
}
</style>