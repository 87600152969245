<template>
  <div class="Main_th" id="zonghe">
    <div class="content">
      <!-- 导航 -->
      <div class="nav">
        <ul>
          <li>
            <a
              href="#home"
              @mouseenter="enter(1)"
              @mouseleave="leave()"
              :class="isShow === 1 ? 'active' : ''"
              ref="onePage"
              >首页</a
            >
          </li>
          <li>
            <a
              href="#xiaoying"
              @mouseenter="enter(3)"
              @mouseleave="leave()"
              :class="isShow === 3 ? 'active' : ''"
              >小鹰加油</a
            >
          </li>
          <li><a href="#zonghe" class="active">综合能源</a></li>
          <li>
            <a
              href="#chanpin"
              @mouseenter="enter(4)"
              @mouseleave="leave()"
              :class="isShow === 4 ? 'active' : ''"
              >产品矩阵</a
            >
          </li>
          <li>
            <a
              href="#IP"
              @mouseenter="enter(5)"
              @mouseleave="leave()"
              :class="isShow === 5 ? 'active' : ''"
              >IP体系</a
            >
          </li>
          <li>
            <a
              href="#zhaoxian"
              @mouseenter="enter(6)"
              @mouseleave="leave()"
              :class="isShow === 6 ? 'active' : ''"
              >招贤纳士</a
            >
          </li>
          <li>
            <a
              href="#aboutUs"
              @mouseenter="enter(7)"
              @mouseleave="leave()"
              :class="isShow === 7 ? 'active' : ''"
              >关于我们</a
            >
          </li>
        </ul>
      </div>
      <div class="content_right reveal-top">
        <!-- 标题 -->
        <div class="header_th" @click="change" ref="change">
          <div class="title">
            <span>综合能源补给港</span>
          </div>
          <div class="introduce">
            <span
              >环球通积极响应国家“碳达峰”和“碳中和”的双碳政策，与全国多省市地区的工业园区实施综合能源补给中心。
              除配备传统能源外，还以园区数据匹配新能源设备及清洁能源供给，能源种类多元，补给动线灵活，
              为工业园区减少碳排放打造试点模式，积极落实国家政策。</span
            >
          </div>
        </div>
        <!-- 轮播 -->
        <div class="recommendPage">
          <swiper :options="swiperOption" ref="mySwiper">
            <swiper-slide>
              <div class="swiperImg">
                <img src="@/assets/third/mode.png" alt="" />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="swiperImg">
                <img src="@/assets/third/mode.png" alt="" />
              </div>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
            <div
              class="swiper-button-prev"
              slot="button-prev"
              @click="clickLeft"
            ></div>
            <div
              class="swiper-button-next"
              slot="button-next"
              @click="clickLeft"
            ></div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import scrollReveal from "scrollreveal";
export default {
  name: "Home",
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      isShow: 0,
      scrollReveal: scrollReveal(),
      swiperOption: {
        loop: true,
        speed: 1000,
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          //下一张
          nextEl: ".swiper-button-next", //下一张标签类名可以自定义
          //上一张
          prevEl: ".swiper-button-prev", //上一张标签类名可以自定义
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted() {
    // current swiper instance
    // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
    console.log("this is current swiper instance object", this.swiper);
    // this.swiper.slideTo(3, 1000, false);
    this.scrollReveal.reveal(".reveal-top", {
      // 动画的时长
      duration: 1000,
      // 延迟时间
      delay: 500,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: "right",
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: "200px",
      // 其他可用的动画效果
      opacity: 0.001,
      easing: "linear",
      scale: 0.9,
    });
  },
  methods: {
    enter(index) {
      // this.$refs.onePage.style.color = "#fff"
      this.isShow = index;
    },
    leave() {
      // this.$refs.onePage.style.color = "#c1d7ce"
      this.isShow = 0;
    },
    clickLeft() {
      this.change();
    },
    change() {
      this.$refs.change.style.opacity = 0;
      setTimeout(() => {
        this.$refs.change.style.opacity = 1;
        this.$refs.change.style.transition = "1s";
      }, 700);
    },
  },
};
</script>
<style scoped lang='scss'>
::v-deep .swiper-pagination-bullet {
  width: 150px;
  margin: 0;
  height: 13px;
  border-radius: 5px;
  background-color: #c8ffe7;
  transform: translateX(0px);
  opacity: 0;
  transition: 1s;
}
::v-deep .swiper-pagination-bullet-active {
  width: 150px;
  height: 13px;
  border-radius: 5px;
  background-color: #c8ffe7;
  // transform: translateX(150px);
  opacity: 1;
  transition: 1s;
}


::v-deep .swiper-container-horizontal > .swiper-pagination-bullets {
  padding: 2px 0;
  bottom: 20px;
  left: 50%;
  width: 340px;
  height: 16px;
  transform: translateX(-50%);
  background-color: #5b7a6d;
  border-radius: 8px;
  // transition: 1s;
}

.swiper-button-prev {
  width: 83px;
  height: 83px;
  background: url("@/assets/third/left.png") no-repeat;
  background-size: 100% 100%;
}
.swiper-button-next {
  width: 83px;
  height: 83px;
  background: url("@/assets/third/right.png") no-repeat;
  background-size: 100% 100%;
}
.recommendPage .swiper-container {
  position: relative;
  width: 1000px;
  height: 600px;
}
.recommendPage .swiper-container .swiper-slide {
  width: 100%;
  line-height: 200px;
  color: #000;
  font-size: 16px;
  text-align: center;
}
.Main_th {
  width: 100vw;
  height: 100vh;
  background-image: url(@/assets/third/bc_th.png);
  background-position: center;
  background-size: cover;
  background-position: center;
}
.header_th {
  display: block;
  text-align: center;
  margin-bottom: 50px;
}
.title {
  padding-top: 78px;
}
.title span {
  font-size: 47px;
  font-family: "SourceHanSansOLD";
  font-weight: 400;
  color: #ffffff;
}
.introduce {
  margin: 38px auto 0;
  width: 965px;
  height: 92px;
  font-size: 20px;
  color: #ffffff;
  line-height: 36px;
  letter-spacing: 6px;
}
.content {
  display: flex;
  max-width: 1720px;
  margin: 0 auto;
}
.nav {
  width: 268px;
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #616162;
  margin: auto 0;
  text-align: center;
}
.nav ul li {
  margin-top: 37px;
  list-style: none;
  display: block;
  height: 37px;
}
.nav ul li a {
  color: #c1d7ce;
  font-size: 22px;
  width: 90px;
}
.active {
  box-sizing: border-box;
  display: inline-block;
  color: #fff !important;
  border-bottom: 4px solid #5b7a6d;
  transition: 0.5s;
}
.content_right {
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
}
.swiperImg {
  width: 100%;
  height: 100%;
  img {
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (min-width: 1920px) {

}
@media (max-width: 1500px) {

  .recommendPage .swiper-container {
    position: relative;
    width: 790px;
    height:402px;
  }
    .swiper-button-next{
    width: 55px;
    height: 55px;
}
  .swiper-button-prev{
    width: 55px;
    height: 55px;
}

.swiper-pagination-bullets{
  display: none;
}

}
@media (max-width: 1400px) {

  .recommendPage .swiper-container {
    position: relative;
    width: 790px;
    height:402px;
  }
    .swiper-button-next{
    width: 55px;
    height: 55px;
}
  .swiper-button-prev{
    width: 55px;
    height: 55px;
}

.swiper-pagination-bullets{
  display: none;
}

}
@media (max-width: 1300px) {
  .nav {
    display: none;
  }
    .recommendPage .swiper-container {
    position: relative;
    width: 690px;
    height:302px;
  }
}
@media (max-width: 1100px) {
  .recommendPage .swiper-container {
    position: relative;
    width: 690px;
    height:302px;
  }
}
@media (max-width: 988px) {
  .title span[data-v-61165efc] {
    font-size: 33px;
}
  .recommendPage .swiper-container {
    width: 890px;
    height: 502px;
  }
  .introduce{
    margin: 38px auto 150px;
    width: 665px;
    font-size: 20px;
    color: #ffffff;
    line-height: 36px;
    letter-spacing: 6px;
  }
  .recommendPage .swiper-container {
    width: 690px;
    height: 302px;
  }
  .swiper-button-next{
    width: 66px;
    height: 66px;
}
  .swiper-button-prev{
    width: 66px;
    height: 66px;
}
}
@media (max-width: 720px) {
  .title span[data-v-61165efc] {
    font-size: 24px;
}
  .introduce{
    margin: 38px auto 150px;
    width: 565px;
}
  .recommendPage .swiper-container {
    width:590px;
    height: 302px;
  }
  .swiper-button-next{
    width: 55px;
    height: 55px;
}
  .swiper-button-prev{
    width: 55px;
    height: 55px;
}
}
@media (max-width: 600px) {
  .title span[data-v-61165efc] {
    font-size: 24px;
}
  .introduce{
    margin: 38px auto 150px;
    width: 465px;
}
  .recommendPage .swiper-container {
    width:490px;
    height: 202px;
  }
  .swiper-button-next{
    width: 44px;
    height: 44px;
}
  .swiper-button-prev{
    width: 44px;
    height: 44px;
}
.swiper-pagination-bullets{
  display: none;
}
}
@media (max-width: 500px) {
  .title span[data-v-61165efc] {
    font-size: 24px;
}
  .introduce{
    margin: 38px auto 150px;
    width: 365px;
    line-height: 20px;
}
.introduce span{
  font-size: 12px;
}
  .recommendPage .swiper-container {
    width:350px;
    height: 162px;
  }
  .swiper-button-next{
    width: 22px;
    height: 22px;
}
  .swiper-button-prev{
    width: 22px;
    height: 22px;
}

}
</style>