<template>
  <div class="warp" id="aboutUs">
    <div>
      <div class="header">
        <div class="area">
          <div class="nav">
            <ul>
              <li>
                <a
                  href="#"
                  @mouseenter="enter(1)"
                  @mouseleave="leave()"
                  :class="isShow === 1 ? 'active' : ''"
                  ref="onePage"
                  >首页</a
                >
              </li>
              <li>
                <a
                  href="#zonghe"
                  @mouseenter="enter(2)"
                  @mouseleave="leave()"
                  :class="isShow === 2 ? 'active' : ''"
                  >综合能源</a
                >
              </li>
              <li>
                <a
                  href="#xiaoying"
                  @mouseenter="enter(3)"
                  @mouseleave="leave()"
                  :class="isShow === 3 ? 'active' : ''"
                  >小鹰加油</a
                >
              </li>

              <li>
                <a
                  href="#chanpin"
                  @mouseenter="enter(4)"
                  @mouseleave="leave()"
                  :class="isShow === 4 ? 'active' : ''"
                  >产品矩阵</a
                >
              </li>
              <li>
                <a
                  href="#IP"
                  @mouseenter="enter(5)"
                  @mouseleave="leave()"
                  :class="isShow === 5 ? 'active' : ''"
                  >IP体系</a
                >
              </li>
              <li>
                <a
                  href="#zhaoxian"
                  @mouseenter="enter(6)"
                  @mouseleave="leave()"
                  :class="isShow === 6 ? 'active' : ''"
                  >招贤纳士</a
                >
              </li>
              <li>
                <a href="#aboutUs" class="active" style="color: #000"
                  >关于我们</a
                >
              </li>
            </ul>
          </div>
          <div class="introduce">
            <div class="base">
              <div>关于环球通</div>
              <div>
                环球通科技有限公司是 "以智慧服务燃动能源未来"
                的提倡者和践行者,隶属于中国国际能源控股集团,成立于2018年10月。拥有小鹰加油
                网络贷运平台等业务,为能源上下游企业提供专业 高效的
                "互联网+运营+服务+大数据"
                综合解决方案,持续赋能"绿色新基建",助推能源行业健康奔跑。
              </div>
            </div>
            <div class="slogan">
              <div class="title">愿景</div>
              <div class="tlist">
                <div>以智慧服务"燃动"能源未来</div>
                <div>用智慧能源实现"零碳"发展</div>
              </div>
            </div>
            <div class="slogan as">
              <div class="title">使命</div>
              <div class="tlist">
                <div>构建智慧能源共同体,</div>
                <div>引领能源数字化工业革命。</div>
              </div>
            </div>
            <div class="slogan as">
              <div class="title">价值观</div>
              <div class="tlists">
                <div>
                  <div>严 肃</div>
                  <div>活 泼</div>
                  <div>团 结</div>
                  <div>上 进</div>
                </div>
                <div>事 情 面 前 不 讲 感 情</div>
              </div>
            </div>
            <div class="mapwarp">
              <div class="map" id="container"></div>
              <div>北京市朝阳区朝阳门外大街3号院</div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="areas">
          <div>
            <header>合作品牌</header>
            <section>
              <ul class="imglist">
                <li><img src="../../assets/footer/whitebar.jpg" alt="" /></li>
                <li><img src="../../assets/footer/wx.jpg" alt="" /></li>
                <li><img src="../../assets/footer/zfb.jpg" alt="" /></li>
                <li><img src="../../assets/footer/modern.jpg" alt="" /></li>
                <li><img src="../../assets/footer/panda.jpg" alt="" /></li>
                <li><img src="../../assets/footer/combo.jpg" alt="" /></li>
                <li><img src="../../assets/footer/wcar.jpg" alt="" /></li>
                <li><img src="../../assets/footer/scar.jpg" alt="" /></li>
                <li><img src="../../assets/footer/pingan.jpg" alt="" /></li>
                <li><img src="../../assets/footer/sto.jpg" alt="" /></li>
                <li><img src="../../assets/footer/ane.jpg" alt="" /></li>
                <li><img src="../../assets/footer/g7.jpg" alt="" /></li>
                <li><img src="../../assets/footer/ty.jpg" alt="" /></li>
                <li><img src="../../assets/footer/dd.jpg" alt="" /></li>
              </ul>
            </section>
            <footer>
              <div class="foothead">
                <div>北京市朝阳区朝阳门外大街3号院</div>
                <div>010-56202210</div>
              </div>
              <div class="footbottom">
                <div>
                  <div style="margin-bottom: 5px">环球通科技有限公司</div>
                  <div>京ICP备18063418号-4</div>
                </div>
                <div>
                  <div></div>
                  <div>京公网安备 11010518063418号</div>
                </div>
                <div>Copyright © 2022 环球通科技 版权所有</div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: 0,
    };
  },
  created() {},
  mounted: function () {
    this.init();
  },
  methods: {
    // 初始化地图
    init: function () {
      var map = new AMap.Map("container", {
        center: [116.44863, 39.924528],
        resizeEnable: true,
        zoom: 20,
      });
      // // 添加工具
      // 添加点标记
      var marker = new AMap.Marker({
        position: new AMap.LngLat(116.44863, 39.924528),
        icon: "",
        label: {
          offset: new AMap.Pixel(-152, -170),
        },
      });
      map.add(marker);
    },
    enter(index) {
      // this.$refs.onePage.style.color = "#fff"
      this.isShow = index;
    },
    leave() {
      // this.$refs.onePage.style.color = "#c1d7ce"
      this.isShow = 0;
    },
    clickLeft() {
      this.change();
    },
  },
};
</script>
<style lang='scss' scoped>
.warp {
  width: 100%;
  height: 100vh;
}

.mapwarp {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 590px;
  right: 292px;
  align-items: center;
}
.mapwarp :nth-child(2) {
  font-size: 21px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #000000;
  margin-top: 14px;
}
.header {
  background: #d6d5d3;
  width: 100%;
  height: 1094px;
  display: flex;
  background: url("../../assets/footer/regard.png") no-repeat center;
  background-size: 100% 100%;
  .introduce {
    position: relative;
    padding-left: 40px;
    width: 100%;

    .base {
      // display: flex;
      // align-items: center;
      margin-top: 124px;
    }
    .base :nth-child(1) {
      font-size: 46px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #000000;
      margin-right: 68px;
    }
    .base :nth-child(2) {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
      width: 1025px;
      margin-top: 47px;
      line-height: 36px;
    }
    .image {
      display: flex;
      margin-top: 73px;
      img {
        width: 194px;
        height: 239px;
        margin-top: 10px;
        margin-right: 47px;
      }
      .color {
        .colortit {
          font-size: 39px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #2c374f;
        }
        .as {
          margin-top: 34px;
        }
        .content {
          font-size: 21px;
          font-family: Source Han Sans CN;
          font-weight: 300;
          color: #000000;
          margin-top: 12px;
          width: 827px;
        }
      }
    }
    .slogan {
      width: 800px;
      margin-top: 90px;
      display: flex;
      .tlists {
        margin-left: 40px;
        > div {
          width:300px;
          display: flex;
          justify-content: space-between;
          font-size: 23px;
        line-height: 40px;

          > div {
            font-size: 23px;
          }
        }
      }
      .tlist {
        margin-left: 40px;
        > div {
          font-size: 23px;
          width: 380px;
          text-align: justify;
          text-align-last: justify;
          line-height: 40px;

          > div {
            font-size: 23px;
          }
        }
      }
    }
    .title {
      width: 120px;
      font-size: 40px;
      text-align-last: justify;
      text-align: justify;
      color: #636a7a;
      font-weight: 550;
    }

    .core :nth-child(1) {
      font-size: 39px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #2c374f;
      margin-top: 22px;
    }
    .core :nth-child(2) {
      font-size: 38px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
      margin-top: 26px;
    }
    .map {
      width: 429px;
      height: 278px;
      z-index: 9;
    }
  }
}
.footer {
  background: #3f3f3f;
  font-family: Source Han Sans CN;
}
.area {
  width: 1596px;

  margin: 0 auto;
  display: flex;
}
.areas {
  width: 1596px;

  margin: 0 auto;
}
header {
  font-size: 38px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #a4a4a4;
  text-align: center;
  padding: 18px 0 20px 0;
}
footer {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  .foothead {
    text-align: center;
    font-size: 21px;
    color: #a1a1a1;
    font-weight: 400;
  }
  .footbottom {
    margin-top: 8px;
    font-weight: 400;
    color: #a4a4a4;
    font-size: 17px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
.imglist {
  width: 1252px;
  margin: 0 auto;
  display: flex;
  list-style: none;
  justify-content: space-between;
  flex-wrap: wrap;
  img {
    width: 150px;
    height: 150px;
  }
  li {
    margin: 0 0 30px 23px;
  }
}
.nav {
  min-width: 105px;
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  margin-top: 209px;
  margin-right: 39px;
}
.nav ul li {
  text-align: center;
  margin-top: 37px;
  list-style: none;
  height: 36px;
}
.nav ul li a {
  color: #616162;
  font-size: 22px;
  width: 90px;
}

.active {
  box-sizing: border-box;
  display: inline-block;
  color: #000000 !important;
  border-bottom: 4px solid #000000;
  transition: 0.5s;
}
.as {
  margin-top: 100px !important;
}
</style>