<template>
  <div>
    <HomePage />
    <SecondPage />
    <ThirdPage />
    <Product />
    <IPhatch />
    <FourthPage />
    <Copyright />
  </div>
</template>

<script>
import HomePage from "./otherPage/HomePage.vue";
import SecondPage from "./otherPage/SecondPage.vue";
import ThirdPage from "./otherPage/ThirdPage.vue";
import IPhatch from "./otherPage/IPhatch.vue";
import FourthPage from "./otherPage/FourthPage.vue";
import Copyright from "./otherPage/Copyright.vue";
import Product from "./otherPage/Product.vue";
export default {
  components: {
    HomePage,
    SecondPage,
    ThirdPage,
    IPhatch,
    FourthPage,
    Copyright,
    Product,
  },
};
</script>
