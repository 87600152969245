<template>
  <div class="warp" id="zhaoxian">
    <div class="Img"><img src="../../assets/invite/trumpet.png" alt="" /></div>
    <div class="area">
      <!-- 导航 -->
      <div class="lef">
        <div class="nav">
          <ul>
            <li>
              <a
                href="#"
                @mouseenter="enter(1)"
                @mouseleave="leave()"
                :class="isShow === 1 ? 'active' : ''"
                ref="onePage"
                >首页</a
              >
            </li>
            <li>
              <a
                href="#zonghe"
                @mouseenter="enter(2)"
                @mouseleave="leave()"
                :class="isShow === 2 ? 'active' : ''"
                >综合能源</a
              >
            </li>
            <li>
              <a
                href="#xiaoying"
                @mouseenter="enter(3)"
                @mouseleave="leave()"
                :class="isShow === 3 ? 'active' : ''"
                >小鹰加油</a
              >
            </li>

            <li>
              <a
                href="#chanpin"
                @mouseenter="enter(4)"
                @mouseleave="leave()"
                :class="isShow === 4 ? 'active' : ''"
                >产品矩阵</a
              >
            </li>
            <li>
              <a
                href="#IP"
                @mouseenter="enter(5)"
                @mouseleave="leave()"
                :class="isShow === 5 ? 'active' : ''"
                >IP体系</a
              >
            </li>
            <li>
              <a href="#zhaoxian" class="active" style="color: #ffffff"
                >招贤纳士</a
              >
            </li>
            <li>
              <a
                href="#aboutUs"
                @mouseenter="enter(7)"
                @mouseleave="leave()"
                :class="isShow === 7 ? 'active' : ''"
                >关于我们</a
              >
            </li>
          </ul>
        </div>
        <div class="reveal">
          <div class="hader">
            <div class="hadertop">
              <div>招贤纳士</div>
              <div>
                环球通科技现面向人才市场开放海量职位，期待你的加入，共同燃烧热血！
                人生有很多种可能，只要勇敢一次，往往能得到的东西能比想象中多得多!
              </div>
            </div>
            <div class="list">
              <div class="listw animation2">
                <div class="mess">
                  <div class="profile animation3">
                    <img src="../../assets/invite/fu.jpg" alt="" />
                  </div>
                  <div class="care">
                    <div>付依哲</div>
                    <div>人力资源总监</div>
                    <div>
                      10年人力资源工作经验， 擅长人才选拔，组织激励，
                      推动组织变革与创新，实现 组织的持续发展，独具人力
                      资源战略视角。
                    </div>
                  </div>
                </div>
                <div class="Imgs">
                  <img src="../../assets/invite/letter.png" alt="" />
                  <div>fuyizhe@cngotone.com</div>
                </div>
              </div>
              <div class="listw animation2">
                <div class="mess">
                  <div class="profile animation3">
                    <img src="../../assets/invite/tan.jpg" alt="" />
                  </div>
                  <div class="care">
                    <div>谭秋颖</div>
                    <div>人力资源总监</div>
                    <div>
                      8年人力资源工作经验， 擅长把控战略方向，将战略与
                      文化在业务部门进行有效落地， 为推动业绩达成提供专业的人
                      力资源解决方案。
                    </div>
                  </div>
                </div>
                <div class="Imgs">
                  <img src="../../assets/invite/letter.png" alt="" />
                  <div>fuyizhe@cngotone.com</div>
                </div>
              </div>
              <div class="listw animation2">
                <div class="mess">
                  <div class="profile animation3">
                    <img src="../../assets/invite/feng.png" alt="" />
                  </div>
                  <div class="care">
                    <div>丰明月</div>
                    <div>人力资源总监</div>
                    <div>
                      12年HR管理工作经验， 擅长以人力资源数据管理，
                      精于社会招聘、定向招聘。
                    </div>
                  </div>
                </div>
                <div class="Imgs">
                  <img src="../../assets/invite/letter.png" alt="" />
                  <div>fuyizhe@cngotone.com</div>
                </div>
              </div>
            </div>
          </div>
          <div class="titwarp animation4">
            <div>
              <div class="tricorn">
                <div>
                  研<br />
                  发<br />类
                </div>
              </div>
              <div class="tricorn">
                <div>职<br />能<br />类</div>
              </div>
              <div class="tricorn">
                <div>市<br />场<br />类</div>
              </div>
              <div class="tricorn">
                <div>市<br />场<br />类</div>
              </div>
            </div>
            <section class="section">
              <!-- 轮播 -->
              <div class="recommendPage">
                <swiper :options="swiperOption" ref="mySwiper">
                  <swiper-slide>
                    <div class="invite">
                      <div class="invitemodule">
                        <div class="invite-top">
                          <div class="inviteTitle">产品研发部</div>
                          <div class="inviteRight">
                            <i>招聘ing</i>
                            <div>高级JAVA工程师</div>
                          </div>
                        </div>
                        <div class="invite-bottom">
                          <div style="border: 0">岗位要求：</div>
                          <div>1、本科以上学历，计算机相关专业，</div>
                          <div>5年以上相关岗位工作经验;</div>

                          <div>2、具扎实的Java基础知识，熟练掌握</div>

                          <div>基础数据结构和算法，深入了解</div>
                          <div>MySQL数据库；熟悉常用的设计模</div>
                        </div>
                        <div class="btn">投递简历</div>
                      </div>
                    </div></swiper-slide
                  >
                  <swiper-slide>
                    <div class="invite">
                      <div class="invitemodule">
                        <div class="invite-top">
                          <div class="inviteTitle">产品研发部</div>
                          <div class="inviteRight">
                            <i>招聘ing</i>
                            <div>中级JAVA工程师</div>
                          </div>
                        </div>
                        <div class="invite-bottom">
                          <div style="border: 0">岗位要求：</div>
                          <div>1、本科以上学历，计算机相关专业，</div>
                          <div>3年以上相关岗位工作经验;</div>

                          <div>2、具扎实的Java基础知识，熟练掌握</div>

                          <div>基础数据结构和算法，深入了解</div>
                          <div>MySQL数据库；熟悉常用的设计模</div>
                        </div>
                        <div class="btn">投递简历</div>
                      </div>
                    </div></swiper-slide
                  >
                  <swiper-slide>
                    <div class="invite">
                      <div class="invitemodule">
                        <div class="invite-top">
                          <div class="inviteTitle">产品研发部</div>
                          <div class="inviteRight">
                            <i>招聘ing</i>
                            <div>高级测试工程师</div>
                          </div>
                        </div>
                        <div class="invite-bottom">
                          <div style="border: 0">岗位要求：</div>
                          <div>1、本科以上学历，计算机相关专业，</div>
                          <div>5年以上相关岗位工作经验;</div>

                          <div>2、熟悉测试流程和规范，熟练掌握</div>

                          <div>软件测试方法和常用测试工具，有性</div>
                          <div>能测试经验、自动化测试、安全性测</div>
                        </div>
                        <div class="btn">投递简历</div>
                      </div>
                    </div></swiper-slide
                  >
                  <swiper-slide>
                    <div class="invite">
                      <div class="invitemodule">
                        <div class="invite-top">
                          <div class="inviteTitle">产品研发部</div>
                          <div class="inviteRight">
                            <i>招聘ing</i>
                            <div>高级JAVA工程师</div>
                          </div>
                        </div>
                        <div class="invite-bottom">
                          <div style="border: 0">岗位要求：</div>
                          <div>1、本科以上学历，计算机相关专业，</div>
                          <div>5年以上相关岗位工作经验;</div>

                          <div>2、具扎实的Java基础知识，熟练掌握</div>

                          <div>基础数据结构和算法，深入了解</div>
                          <div>MySQL数据库；熟悉常用的设计模</div>
                        </div>
                        <div class="btn">投递简历</div>
                      </div>
                    </div></swiper-slide
                  >
                  <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <div class="Imgn"><img src="../../assets/invite/trumper.png" alt="" /></div>
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import scrollReveal from "scrollreveal";
export default {
  name: "Home",
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      isShow: 0,
      scrollReveal: scrollReveal(),
      swiperOption: {
        loopedSlides: 4,
        // loop: true,
        slidesPerView: "auto", //默认1, 同时显示的slides数量,auto 代表根据轮播图的宽度排列
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted() {
    // current swiper instance
    // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
    console.log("this is current swiper instance object", this.swiper);
    // this.swiper.slideTo(3, 1000, false);

    this.scrollReveal.reveal(".reveal", {
      // 动画的时长
      duration: 2000,
      // 延迟时间
      delay: 500,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: "top",
      // 回滚的时候是否再次触发动画
      reset: true,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: "200px",
      // 其他可用的动画效果
      opacity: 0.001,
      easing: "linear",
      scale: 0.9,
    });
  },
  methods: {
    enter(index) {
      // this.$refs.onePage.style.color = "#fff"
      this.isShow = index;
    },
    leave() {
      // this.$refs.onePage.style.color = "#c1d7ce"
      this.isShow = 0;
    },
    clickLeft() {
      this.change();
    },
  },
};
</script>

<style scoped lang='scss'>
::v-deep .swiper-pagination-bullet {
  width: 150px;
  margin: 0;
  height: 13px;
  border-radius: 5px;
  background-color: #c8ffe7;
  transform: translateX(0px);
  opacity: 0;
  transition: 1s;
}
::v-deep .swiper-pagination-bullet-active {
  width: 150px;
  height: 13px;
  border-radius: 5px;
  background-color: #c8ffe7;
  // transform: translateX(150px);
  opacity: 1;
  transition: 1s;
}
 .swiper-container {
  height: 100% !important;
}
::v-deep .swiper-container-horizontal > .swiper-pagination-bullets {
  padding: 2px 0;
  bottom: 20px;
  left: 50%;
  width: 340px;
  height: 16px;
  transform: translateX(-50%);
  background-color: #5b7a6d;
  border-radius: 8px;
  // transition: 1s;
}
.recommendPage .swiper-container {
  position: relative;
  width: 1291px;

  // height: 600px;
  // background: pink;
}
.recommendPage .swiper-container .swiper-slide {
  width: 30%;
  // line-height: 200px;
  // background: yellowgreen;
  color: #000;
  font-size: 16px;
  text-align: center;
}
.section {
  // width: 1291px;
  height: 518px;
  background: #3b6aa4;
  display: flex;
}
.area {
  width: 1596px;

  margin: 0 auto;
}
.warp {
  height: 100vh;
  background: url("../../assets/invite/background.png") no-repeat center;
  background-size: 100% 100%;
  position: relative;
}
.Img {
  position: absolute;
  top: 137px;
  left: 64px;
  z-index: 0;
  img {
    width: 290px;
    height: 310px;
  }
}
.Imgn {
  position: absolute;
  top: 137px;
  right: 64px;
  z-index: 0;
  img {
    width: 290px;
    height: 310px;
  }
}

.hadertop {
  display: flex;
  align-items: center;
  padding-top: 44px;
}
.hadertop :nth-child(1) {
  font-size: 60px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-right: 83px;
}
.hadertop :nth-child(2) {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  width: 881px;
}
.profile {
  width: 182px;
  height: 182px;
  border-radius: 50%;
  background-color: aqua;
  img {
    border-radius: 50%;
  }
}
.lef {
  display: flex;
}
.care {
  width: 199px;
  margin-left: 12px;
}
.care :nth-child(1) {
  font-size: 32px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-top: 11px;
}
.care :nth-child(2) {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-style: italic;
  color: #ffffff;
  margin-top: 12px;
}
.care :nth-child(3) {
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-top: 9px;
}
.Imgs {
  font-size: 21px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  text-decoration: underline;
  color: #50ecff;
  display: flex;
  margin-top: 15px;
}
.Imgs img {
  width: 41px;
  height: 26px;
  margin-left: 70px;
}
.Imgs :nth-child(2) {
  margin-left: 50px;
  font-size: 21px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  text-decoration: underline;
  color: #50ecff;
}
.mess {
  display: flex;
}
.list {
  margin-top: 35px;
  display: flex;
  justify-content: space-around;
}
.listw {
  width: 400px;
}
// 轮播

.invite {
  width: 339px;
  height: 451px;
  background: #ffffff;
  border-radius: 23px;
  padding-left: 30px;
  margin-top: 18px;
  margin-left: 40px;
  .invite-top {
    display: flex;
  }
  .inviteTitle {
    width: 130px;
    height: 100px;
    background: #d8d8d8;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    margin-top: 37px;
  }
  .inviteRight :nth-child(1) {
    font-size: 44px;
    font-family: MFLangQian_Noncommercial;
    font-weight: bold;
    color: #3d3e4e;
    margin-top: 22px;
    margin-left: 16px;
  }
  .inviteRight :nth-child(2) {
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #3d3e4e;
    margin-left: 16px;
  }
  .invite-bottom {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #3d3e4e;
    > div {
      margin-top: 7px;
      padding-bottom: 1px;
      border-bottom: 1px solid #3b8ff7;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #3d3e4e;
    }
  }
  .btn {
    width: 164px;
    height: 41px;
    background: #ffffff;
    border: 4px solid #3b8ff7;
    border-radius: 21px;
    text-align: center;
    line-height: 32px;
    // margin: 0 auto;
    margin-left: 60px;

    margin-top: 15px;
  }
}
.tricorn {
  height: 104.6px;
  width: 70px;
  font-size: 21px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  background: #1d3e67;
  border-top-left-radius: 23px;
  border-bottom-left-radius: 23px;
  margin-bottom: 2px;
  // line-height: 70px;

  > div {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // text-align: center;
    // width: 21px;
    text-align: center;
  }
}
.titwarp {
  display: flex;
  margin-top: 28px;
}
.nav {
  width: 105px;
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 500;

  color: #b3c1ff;
  margin-right: 33px;
  margin-top: 381px;
}
.nav ul li {
  text-align: center;
  margin-top: 37px;
  list-style: none;
  height: 36px;
}
.nav ul li a {
  color: #b3c1ff;
  font-size: 22px;
  width: 90px;
}

.active {
  box-sizing: border-box;
  display: inline-block;
  color: #FFF !important;
  border-bottom: 4px solid #ffffff;
  transition: 0.5s;
}

</style>