import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
    // import '@/rem/rem.js'
import './assets/font/iconfont.css'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')