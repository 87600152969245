<template>
  <div class="warp" id="IP">
    <div class="area">
      <!-- 导航 -->
      <div class="lef">
        <div class="nav">
          <ul>
            <li>
              <a
                href="#"
                @mouseenter="enter(1)"
                @mouseleave="leave()"
                :class="isShow === 1 ? 'active' : ''"
                ref="onePage"
                >首页</a
              >
            </li>
            <li>
              <a
                href="#zonghe"
                @mouseenter="enter(2)"
                @mouseleave="leave()"
                :class="isShow === 2 ? 'active' : ''"
                >综合能源</a
              >
            </li>
            <li>
              <a
                href="#xiaoying"
                @mouseenter="enter(3)"
                @mouseleave="leave()"
                :class="isShow === 3 ? 'active' : ''"
                >小鹰加油</a
              >
            </li>

            <li>
              <a
                href="#chanpin"
                @mouseenter="enter(4)"
                @mouseleave="leave()"
                :class="isShow === 4 ? 'active' : ''"
                >产品矩阵</a
              >
            </li>
            <li>
              <a href="#IP" class="active" style="color: #3d3e4e">IP体系</a>
            </li>
            <li>
              <a
                href="#zhaoxian"
                @mouseenter="enter(6)"
                @mouseleave="leave()"
                :class="isShow === 6 ? 'active' : ''"
                >招贤纳士</a
              >
            </li>
            <li>
              <a
                href="#aboutUs"
                @mouseenter="enter(7)"
                @mouseleave="leave()"
                :class="isShow === 7 ? 'active' : ''"
                >关于我们</a
              >
            </li>
          </ul>
        </div>
        <div class="titles reveal">
          <div class="title">IP孵化</div>
          <div class="Img">
            <div class="zhai">
              <img src="../../assets/IP/zhaixing.png" alt="" />
            </div>
            <div class="qi"><img src="../../assets/IP/qiyue.png" alt="" /></div>
            <div class="fei">
              <img src="../../assets/IP/feiyue.png" alt="" />
            </div>
            <div class="xi">
              <img src="../../assets/IP/xiandan.png" alt="" />
            </div>
            <div class="hu"><img src="../../assets/IP/hufu.png" alt="" /></div>
          </div>
          <div class="meme">
            <div><img src="../../assets/IP/o.png" alt="" /></div>
            <div><img src="../../assets/IP/t.png" alt="" /></div>
            <div><img src="../../assets/IP/s.png" alt="" /></div>
            <div><img src="../../assets/IP/r.png" alt="" /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import scrollReveal from "scrollreveal";

export default {
  data() {
    return {
      isShow: 0,
      scrollReveal: scrollReveal(),
    };
  },
  mounted() {
    // current swiper instance
    // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
    console.log("this is current swiper instance object", this.swiper);
    // this.swiper.slideTo(3, 1000, false);

    this.scrollReveal.reveal(".reveal", {
      // 动画的时长
      duration: 2000,
      // 延迟时间
      delay: 500,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: "top",
      // 回滚的时候是否再次触发动画
      reset: true,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: "100px",
      // 其他可用的动画效果
      opacity: 0.001,
      easing: "linear",
      scale: 0.9,
    });
  },
  methods: {
    enter(index) {
      // this.$refs.onePage.style.color = "#fff"
      this.isShow = index;
    },
    leave() {
      // this.$refs.onePage.style.color = "#c1d7ce"
      this.isShow = 0;
    },
    clickLeft() {
      this.change();
    },
  },
};
</script>



<style scoped lang='scss'>
.area {
  width: 1596px;
  margin: 0 auto;
}
.lef {
  display: flex;
}
.warp {
  height: 100vh;
  background: url("../../assets/IP/back.jpg") no-repeat center;
  background-size: 100% 100%;
}

.title {
  font-size: 78px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #4d4e5b;
  text-align: center;
  padding-top: 64px;
}
.Img {
  margin-top: 67px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > div {
    margin-top: 40px;
    margin-right: 29px;
  }
}
.meme {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  div:nth-of-type(odd) {
    margin-top: 32px;
  }
  .xi {
    width: 462px;
    height: 159px;
  }
  .hu {
    width: 462px;
    height: 159px;
  }
  img {
    width: 156px;
    height: 187px;
    margin-right: 30px;
  }
}
.nav {
  width: 105px;
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #5a6671;
  margin-top: 265px;
}
.nav ul li {
  text-align: center;
  margin-top: 37px;
  list-style: none;
  height: 36px;
}
.nav ul li a {
  color: #5a6671;
  font-size: 22px;
  width: 90px;
}

.active {
  box-sizing: border-box;
  display: inline-block;
  color: #3d3e4e !important;
  border-bottom: 4px solid #3a425a;
  transition: 0.5s;
}
</style>