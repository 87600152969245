<template>
  <div class="Main" id="home">
    <header>
      <div class="logo">
        <img src="@/assets/home/logo.png" alt="" />
      </div>
      <nav>
        <a href="#home" class="active">首页</a>
        <a
          href="#xiaoying"
          @mouseenter="enter(2)"
          @mouseleave="leave()"
          :class="isShow === 2 ? 'active' : ''"
          >小鹰加油</a
        >
        <a
          href="#zonghe"
          @mouseenter="enter(3)"
          @mouseleave="leave()"
          :class="isShow === 3 ? 'active' : ''"
          >综合能源</a
        >
        <a
          href="#chanpin"
          @mouseenter="enter(4)"
          @mouseleave="leave()"
          :class="isShow === 4 ? 'active' : ''"
          >产品矩阵</a
        >
        <a
          href="#IP"
          @mouseenter="enter(5)"
          @mouseleave="leave()"
          :class="isShow === 5 ? 'active' : ''"
          >IP体系</a
        >
        <a
          href="#zhaoxian"
          @mouseenter="enter(6)"
          @mouseleave="leave()"
          :class="isShow === 6 ? 'active' : ''"
          >招贤纳士</a
        >
        <a
          href="#aboutUs"
          @mouseenter="enter(7)"
          @mouseleave="leave()"
          :class="isShow === 7 ? 'active' : ''"
          >关于我们</a
        >
      </nav>
      <!-- 三条横线 -->
      <div class="burger" @click="show">
        <div class="burger-line1" @click="show"></div>
        <div class="burger-line2" @click="show"></div>
        <div class="burger-line3" @click="show"></div>
        <div class="menu" v-if="display">
          <div class="navs">
            <a href="#home" class="active">首页</a>
            <a
              href="#xiaoying"
              @mouseenter="enter(2)"
              @mouseleave="leave()"
              :class="isShow === 2 ? 'active' : ''"
              >小鹰加油</a
            >
            <a
              href="#zonghe"
              @mouseenter="enter(3)"
              @mouseleave="leave()"
              :class="isShow === 3 ? 'active' : ''"
              >综合能源</a
            >
            <a
              href="#chanpin"
              @mouseenter="enter(4)"
              @mouseleave="leave()"
              :class="isShow === 4 ? 'active' : ''"
              >产品矩阵</a
            >
            <a
              href="#IP"
              @mouseenter="enter(5)"
              @mouseleave="leave()"
              :class="isShow === 5 ? 'active' : ''"
              >IP体系</a
            >
            <a
              href="#zhaoxian"
              @mouseenter="enter(6)"
              @mouseleave="leave()"
              :class="isShow === 6 ? 'active' : ''"
              >招贤纳士</a
            >
            <a
              href="#aboutUs"
              @mouseenter="enter(7)"
              @mouseleave="leave()"
              :class="isShow === 7 ? 'active' : ''"
              >关于我们</a
            >
          </div>
        </div>
      </div>
    </header>
    <!-- 背景视频 -->
    <div class="glide">
      <!-- <video src="@/assets/home/fire.mp4" muted autoplay loop></video> -->
      <img src="@/assets/home/bc_img.png" alt="">
    </div>
    <!-- 背景透明山 -->
    <!-- <div class="backdrop">
      <img src="@/assets/home/mountain2.png" alt="" />
    </div> -->
    <!-- 版心 - 字 -->
    <div class="explore">
      <div class="content">
        <div class="ran1">
          <div class="ran">
            <img src="@/assets/home/ran.png" alt="" />
          </div>
        </div>
        <div class="featrue">
          <img src="@/assets/home/featrue.png" alt="" />
        </div>
        <div class="hot">
          <img src="@/assets/home/hot.png" alt="" />
        </div>
        <!-- <div class="btofont">
          <img src="@/assets/home/bto_font.png" alt="" />
        </div>
        <div class="link">
          <a href="www.wonderlink.cc"><span class="iconfont icon-phone"></span>（010）5620 2210</a>
        </div> -->
      </div>
    </div>
    <!-- 遮罩层 -->
    <!-- <div class="mark">
      <img src="@/assets/home/mark.png" alt="" />
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: 0,
      display: false,
    };
  },
  methods: {
    enter(index) {
      // this.$refs.onePage.style.color = "#fff"
      this.isShow = index;
    },
    leave() {
      // this.$refs.onePage.style.color = "#c1d7ce"
      this.isShow = 0;
    },
    show() {
      this.display = !this.display;
    },
  },
};
</script>

<style  lang="scss" scope>
.iconfont{
  font-size: 35px !important;
}
.Main {
  width: 100vw;
  height: 100vh;
  // background-color: pink;
  position: relative;
}
header {
  max-width: 1520px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 24px;
  position: relative;
  z-index: 999;
}

.logo {
  img {
    width: 210px;
    height: 84px;
  }
}

header nav {
  // justify-self: end;
  padding-left: 300px;
}

header nav a {
  display: inline-block;
  width: 88px;
  color: #fff;
  font-size: 22px;
  /* 去掉下划线 */
  text-decoration: none;
  margin: 0 19px;
  font-family: "Source Han Sans CN";
}
.navs a {
  display: inline-block;
  width: 88px;
  color: #fff;
  font-size: 22px;
  /* 去掉下划线 */
  text-decoration: none;
  margin: 2px 19px;
  font-family: "Source Han Sans CN";
}
header .burger {
  display: none;
}
.menu {
  display: none;
  width: 140px;
  height: 230px;
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  animation: slidDown 0.6s ease-out forwards;
}
@keyframes slidDown {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 284px;
    padding-top: 20px;
    opacity: 1;
  }
}
.active {
  display: inline-block;
  height: 40px;
  border-bottom: 4px solid #00b4ff;
  transition: 0.5s;
}
.glide {
  position: relative;
  top: -112px;
  z-index: 10;
}
.glide img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
.backdrop {
  position: absolute;
  bottom: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  img {
    height: 100%;
    object-fit: cover;
  }
}
.explore {
  width: 970px;
  height: 480px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-46%);
  z-index: 30;
}
.content {
  position: relative;
  width: 100%;
  height: 100%;
}
.ran {
  width: 360px;
  height: 269px;
  position: absolute;
  top: 0px;
  left: 150px;
}
.hot {
  width: 190px;
  height: 26x;
  position: absolute;
  right: 250px;
  top: 180px;
}
.featrue {
  width: 240px;
  height: 150px;
  position: absolute;
  right: 230px;
  top: 10px;
}
.btofont {
  width: 80%;
  height: 162px;
  position: absolute;
  bottom: 36px;
  left: 50%;
  transform: translateX(-50%);
}
.link {
  position: absolute;
  width: 390px;
  height: 60px;
  left: 50%;
  bottom: -110px;
  transform: translateX(-50%);
  text-align: center;
  line-height: 60px;
  background-color: rgba(255, 255, 255, 0.56);
}
.link a {
  text-decoration: none;
  font-size: 32.24px;
  color: #3d6aa7;
  font-family: "SourceHanSansCN-Normal";
}
.mark {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 22;
  background-size: 200%;
  height: 100vh;
  background-color: rgba($color: #000, $alpha: 0.3);
}
@keyframes scaleDraw {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    transform: scale(1); /*开始为原始大小*/
  }
  20% {
    transform: scale(1.1); /*放大1.1倍*/
  }
  25% {
    transform: scale(1.2); /*放大1.1倍*/
  }
  30% {
    transform: scale(1.1); /*放大1.1倍*/
  }
  50% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.1); /*放大1.1倍*/
  }
  75% {
    transform: scale(1.2); /*放大1.1倍*/
  }
  80% {
    transform: scale(1.1); /*放大1.1倍*/
  }
}
@keyframes scaleDraw2 {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    transform: translate(100px);
  }
  20% {
    transform: translate(100px);
  }
  40% {
    transform: translate(100px);
  }
  60% {
    transform: translate(100px);
  }
  80% {
    transform: translate(100px);
  }
  100% {
    transform: translate(0px);
  }
}
.ran1 {
  -webkit-animation-name: scaleDraw2; /*关键帧名称*/
  -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
  -webkit-animation-iteration-count: 1; /*动画播放的次数*/
  -webkit-animation-duration: 3s; /*动画所花费的时间*/
}
.ran {
  -webkit-animation-name: scaleDraw; /*关键帧名称*/
  -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
  -webkit-animation-iteration-count: infinite; /*动画播放的次数*/
  -webkit-animation-duration: 2s; /*动画所花费的时间*/
}

@keyframes scaleGradual {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    width: 0; /*开始为原始大小*/
  }
  20% {
    width: 0; /*开始为原始大小*/
  }
  40% {
    width: 0; /*开始为原始大小*/
  }
  60% {
    width: 0; /*开始为原始大小*/
  }
  80% {
    width: 0; /*开始为原始大小*/
  }

  100% {
    scale: 1;
  }
}
.featrue {
  -webkit-animation-name: scaleGradual; /*关键帧名称*/
  -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
  -webkit-animation-iteration-count: 1; /*动画播放的次数*/
  -webkit-animation-duration: 4s; /*动画所花费的时间*/
}
@keyframes danchu {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    opacity: 0; /*开始为原始大小*/
  }
  50% {
    opacity: 0; /*开始为原始大小*/
  }
  100% {
    opacity: 1;
  }
}
.hot {
  -webkit-animation-name: danchu; /*关键帧名称*/
  -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
  -webkit-animation-iteration-count: 1; /*动画播放的次数*/
  -webkit-animation-duration: 8s; /*动画所花费的时间*/
}
// @media (min-width: 1380px) {
//   header nav {
//     // justify-self: end;
//     padding-left: 300px;
//   }
// }
@media (max-width: 1380px) {
  header nav {
    // justify-self: end;
    padding-left: 100px;
  }
}
@media (max-width: 1200px) {
  header nav {
    // justify-self: end;
    padding-left: 50px;
  }
  header nav a {
    font-size: 18px;
    margin: 0 4px;
  }
}
@media (max-width: 960px) {
  nav {
    display: none;
  }
  // .navs {
  //   display: block;
  // }
  .menu {
    display: block;
  }
  header {
    justify-content: space-around;
  }
  header .burger {
    display: block;
    width: 32px;
    height: 26px;
    border: 2px solid #fff;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
  }
  .burger-line1,
  .burger-line2,
  .burger-line3 {
    color: #fff;
    width: 20px;
    height: 2px;
    border-bottom: 2px solid #fff;
    background-color: var(--text-color-lightest);
  }
  .burger-line1 {
    position: absolute;
    top: 2px;
    left: 50%;
    transform: translateX(-50%);
  }
  .burger-line2 {
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
  }
  .burger-line3 {
    position: absolute;
    top: 14px;
    left: 50%;
    transform: translateX(-50%);
  }
  .ran {
    width: 300px;
    position: absolute;
    top: 0px;
    left: 225px;
  }
  .featrue {
    width: 180px;
    height: 150px;
    position: absolute;
    right: 290px;
    top: 20px;
  }
  .hot {
    width: 130px;
    height: 26x;
    position: absolute;
    right: 317px;
    top: 180px;
  }
  .btofont {
    width: 70%;
    height: 162px;
    position: absolute;
    bottom: 17px;
    left: 50%;
    transform: translateX(-50%);
  }
  .link {
    width: 300px;
    height: 50px;
    line-height: 50px;
  }
  .link a {
    font-size: 28px;
  }
}
@media (max-width: 750px) {
  body {
    overflow-x: hidden;
  }
  .iconfont{
  font-size: 28px !important;
}
}
@media (max-width: 700px) {
  .ran {
    width: 270px;
    position: absolute;
    top: 70px;
    left: 225px;
  }
  .featrue {
    width: 180px;
    height: 150px;
    position: absolute;
    right: 290px;
    top: 90px;
  }
  .hot {
    width: 130px;
    height: 26x;
    position: absolute;
    right: 317px;
    top: 220px;
  }
  .btofont {
    width: 50%;
    height: 162px;
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: 550px) {
  .ran {
    width: 240px;
    position: absolute;
    top: 80px;
    left: 288px;
  }
  .featrue {
    width: 140px;
    position: absolute;
    right: 290px;
    top: 100px;
  }
  .hot {
    width: 130px;
    height: 26x;
    position: absolute;
    right: 292px;
    top: 210px;
  }
  .btofont {
    width: 40%;
    height: 162px;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
  .link {
    width: 270px;
    height: 40px;
    line-height: 40px;
  }
  .link a {
    font-size: 24px;
  }
}
@media (max-width: 425px) {
  .iconfont{
  font-size:20px !important;
}
  .ran {
    width: 172px;
    position: absolute;
    top: 129px;
    left: 322px;
  }
  .featrue {
    width: 112px;
    position: absolute;
    right: 363px;
    top: 131px;
  }
  .hot {
    width: 103px;
    height: 26x;
    position: absolute;
    right: 363px;
    top: 220px;
  }
  .btofont {
    width: 30%;
    height: 162px;
    position: absolute;
    bottom: -33px;
    left: 50%;
    transform: translateX(-50%);
  }
  .link {
    width: 240px;
    height: 30px;
    line-height: 30px;
  }
  .link a {
    font-size: 20px;
  }
}
@media (max-width: 350px) {
  .ran {
    width: 150px;
    position: absolute;
    top: 140px;
    left: 351px;
  }
  .featrue {
    width: 93px;
    position: absolute;
    right: 375px;
    top: 147px;
  }
  .hot {
    width: 86px;
    height: 26x;
    position: absolute;
    right: 376px;
    top: 220px;
  }
  .btofont {
    width: 30%;
    height: 162px;
    position: absolute;
    bottom: -33px;
    left: 50%;
    transform: translateX(-50%);
  }
  .link {
    width: 240px;
    height: 30px;
    line-height: 30px;
  }
  .link a {
    font-size: 20px;
  }
}
</style>